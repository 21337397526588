import React from 'react'
import modalStyles from './old_modal.scss'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import Icon from 'components/icon'
import classnames from 'classnames'

class Modal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    hideCloseIcon: PropTypes.bool,
    overflow: PropTypes.bool,
    disableClosing: PropTypes.bool,
  }

  static defaultProps = {
    hideCloseIcon: false,
    overflow: false,
    disableClosing: false,
  }

  state = {
    hasBeenOpened: false,
  }

  onAfterOpen = () => {
    this.setState({ hasBeenOpened: true })
  }

  closeModal = () => {
    this.setState({ hasBeenOpened: false }, () => {
      setTimeout(() => {
        this.props.onRequestClose()
      }, 475)
    })
  }

  render() {
    const {
      children,
      isOpen,
      className,
      hideCloseIcon,
      overflow,
      disableClosing,
      style = {},
    } = this.props
    const { hasBeenOpened } = this.state

    const closeIconVisible = !disableClosing && !hideCloseIcon

    return (
      <ReactModal
        overlayClassName={classnames('modal-overlay', { open: hasBeenOpened })}
        shouldFocusAfterRender={!overflow}
        onRequestClose={disableClosing ? undefined : this.closeModal}
        onAfterOpen={this.onAfterOpen}
        isOpen={isOpen}
        style={style}
        className={classnames('modal-content', {
          [className]: !!className,
          opened: hasBeenOpened,
          'allow-overflow': overflow,
        })}>
        {children}
        {closeIconVisible && <Icon name="close" fontSize="14px" onClick={this.closeModal} />}
        <style jsx global>
          {modalStyles}
        </style>
      </ReactModal>
    )
  }
}

export default Modal
