import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import styles from './index.scss'

// see the react-modal docs for how to use these className objects.
// https://reactcommunity.org/react-modal/styles/classes/
const defaultClassName = {
  base: 'aedit-modal',
  afterOpen: 'aedit-modal--open',
  beforeClose: 'aedit-modal--closing',
}

const defaultOverlayClassName = {
  base: 'aedit-modal-overlay',
  afterOpen: 'aedit-modal-overlay--open',
  beforeClose: 'aedit-modal-overlay--closing',
}

const fullScreenClassName = {
  base: 'aedit-modal fullscreen',
  afterOpen: 'aedit-modal--open fullscreen',
  beforeClose: 'aedit-modal--closing fullscreen',
}

const CloseButton = props => (
  <button className="aedit-modal--close-button" id="modal-close-button" tabIndex={0} {...props}>
    <i className="fa fa-close"></i>
  </button>
)

const Modal = props => {
  const {
    bodyOpenClassName = 'scroll-stop',
    children,
    closeTimeoutMS = 185,
    fullScreen = false,
    className = defaultClassName,
    augmentBaseClass = '',
    overlayClassName = defaultOverlayClassName,
    onRequestClose,
    shouldCloseOnEsc = true,
    showCloseButton = true,
    isOpen,
    portalClassName = '',
    ...rest
  } = props

  const computedClassName = useMemo(() => {
    // preserves the expected behavior: fullScreen only works when invoked
    // *without* a custom className.
    if (fullScreen && className === defaultClassName) {
      fullScreenClassName.base += ' ' + augmentBaseClass
      return fullScreenClassName
    }

    defaultClassName.base += ' ' + augmentBaseClass
    return className
  }, [className, fullScreen, augmentBaseClass])

  useEffect(() => {
    if (isOpen && fullScreen && Boolean(window) && Boolean(document)) {
      const vscroll = window.scrollY

      document.body.style.position = 'fixed'
      document.body.style.top = `-${vscroll}px`
      document.body.style.left = '0'
      document.body.style.right = '0'

      return () => {
        window.scrollTo(0, vscroll)
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.left = ''
        document.body.style.right = ''
      }
    }
  }, [isOpen, fullScreen])

  return (
    <div onClick={e => e.stopPropagation()}>
      <ReactModal
        isOpen={isOpen}
        bodyOpenClassName={bodyOpenClassName}
        closeTimeoutMS={closeTimeoutMS}
        className={computedClassName}
        overlayClassName={overlayClassName}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onRequestClose={onRequestClose}
        portalClassName={`ReactModalPortal ${isOpen ? portalClassName : ''}`}
        {...rest}>
        {showCloseButton ? <CloseButton onClick={onRequestClose} /> : <></>}
        {children}
        <style jsx>{styles}</style>
      </ReactModal>
    </div>
  )
}

Modal.propTypes = {
  fullScreen: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  ...ReactModal.propTypes,
}

export { default as OldModal } from './old_modal'
export default Modal
